import AbstractView from './AbstractView';

export default class extends AbstractView {

    constructor(params) {
        super(params);
        this.setTitle('POST TITLE');
    }

    // This could also be from the server via the fetch API
    async getHtml() {
        return `
            <project-view></project-view>
        `;
    }
}