import AbstractView from './AbstractView';

export default class extends AbstractView {

    constructor(params) {
        super(params);
        this.setTitle('About');
    }

    // This could also be from the server via the fetch API
    async getHtml() {
        return `
            <section id="sectionTwo" class="px-6 py-10 text-center">
                <div class="max-w-screen-xl mx-auto">
                    <h1 class="text-2xl font-bold mb-4">About Us</h1>
                    <p>This is the about page</p>
                </div>
            </section>

            <section id="sectionTwo" class="px-6 py-10 bg-gray-100">
                <div class="max-w-screen-xl mx-auto">

                </div>
            </section>
        `;
    }
}