import projectData from '../../data/projects.json';

export default class ProjectView extends HTMLElement {
    
    connectedCallback() {
        let url = window.location.pathname;
        let id = url.split('/')[2];
        let data = projectData[id];
        this.renderData(data);
    }

    renderData(data) {
        const el = data;
        const container = document.createElement('div');
        container.innerHTML = `
            <section id="sectionTwo" class="px-6 py-10">
                <div class="max-w-screen-xl mx-auto text-center">
                    <h1 class="text-2xl font-bold mb-4">${el.title}</h1>
                    <p class="mb-4">${el.desc}</p>
                    <a href="${el.url}" target="_blank" class="inline-block text-blue-500 font-bold mt-4">${el.url}</a>
                </div>
            </section>

            <section id="sectionTwo" class="px-6 py-10 bg-gray-100">
                <div class="max-w-screen-xl mx-auto">
                    
                    <a href="/projects" class="inline-block text-white border-2 border-blue-500 bg-blue-500 hover:bg-white hover:text-blue-500 px-4 py-2 rounded-lg mt-4 transition transition-hover duration-300" data-link>Back To All Projects</a>
                </div>
            </section>
        `
        this.append(container);
    }
}

customElements.define( 'project-view', ProjectView );