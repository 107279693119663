const navBtn = document.querySelector('#navBtn');
const navMenu = document.querySelector('#navMenu');
const navLinks = document.querySelectorAll('#navLink');

navBtn.addEventListener("click", (e) => {
    navMenu.classList.toggle('invisible')
});

navLinks.forEach(el => {
    var w = window.innerWidth;
    if (w < 768) {
        el.addEventListener("click", () => {
            navMenu.classList.add("invisible")
        })
    } else {
        navMenu.classList.remove('invisible')
    }
});