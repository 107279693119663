import projectData from '../../data/projects.json';

export default class ProjectList extends HTMLElement {
    
    connectedCallback() {
        let data = projectData;
        this.renderData(data);
    }

    renderData(data) {
        this.setAttribute("class", "grid gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4")
        for (let i = 0; i < data.length; i++) {
            const el = data[i];
            const container = document.createElement('div');
            container.setAttribute("class", "relative bg-white px-6 py-10 rounded-lg border-l-4 border-blue-500 hover:bg-blue-500 hover:text-white hover:shadow-xl transition transition-hover duration-300")
            container.innerHTML = `
                <a href="/projects/${el.id}" class="absolute w-full h-full left-0 top-0" data-link></a>
                <h3 class="text-lg font-semibold mb-4">${el.title}</h3>
                <p>${el.desc}</p>
            `
            this.append(container);
        }
    }
}

customElements.define( 'project-list', ProjectList );