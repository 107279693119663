import AbstractView from './AbstractView';

export default class extends AbstractView {

    constructor(params) {
        super(params);
        this.setTitle('Error: 404!');
    }

    // This could also be from the server via the fetch API
    async getHtml() {
        return `
            <section id="sectionTwo" class="px-6 py-10 text-center">
                <div class="max-w-screen-xl mx-auto">
                    <h1 class="text-2xl font-bold mb-4">Error: 404!</h1>
                    <p class="mb-4">The resource you're looking for can't be found</p>
                    <a href="/" class="inline-block text-white border-2 border-blue-500 bg-blue-500 hover:bg-white hover:text-blue-500 px-4 py-2 rounded-lg mt-4 transition transition-hover duration-300" data-link>Return Home</a>
                </div>
            </section>
        `;
    }
}