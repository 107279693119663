// Include Pages
import NotFound from './pages/404'
import Home from './pages/Home'
import About from './pages/About'
import Contact from './pages/Contact'
import Projects from './pages/Projects'
import Project from './pages/Project'

// Include Components
import navToggle from './components/navToggle'
import ProjectList from './components/projectList'
import ProjectView from './components/projectView'

const transitionEl = document.querySelector('#transition')

const transitionIn = async () => {
    transitionEl.classList.remove('invisible')
    transitionEl.classList.remove('opacity-0')
    transitionEl.classList.add('opacity-70')
}

const transitionOut = async () => {
        transitionEl.classList.remove('opacity-70')
        transitionEl.classList.add('opacity-0')
    setTimeout(() => {
        transitionEl.classList.add('invisible')
    }, 300); // This needs to be the same as the transition time
}

const pathToRegex = path => new RegExp("^" + path.replace(/\//g, "\\/").replace(/:\w+/g, "(.+)") + "$");

const getParams = match => {
    const values = match.result.slice(1);
    const keys = Array.from(match.route.path.matchAll(/:(\w+)/g)).map(result => result[1]);

    return Object.fromEntries(keys.map((key, i) => {
        return [key, values[i]];
    }));
};

const navigateTo = url => {
    transitionIn()
    setTimeout(() => {
        history.pushState(null, null, url);
        window.scrollTo(0, 0); //Scroll to top on navigation
        router();
        transitionOut()
    }, 300); //Delay between next page

};

const router = async () => {
    const routes = [
        { path: "/404", view: NotFound },
        { path: "/", view: Home },
        { path: "/about", view: About },
        { path: "/contact", view: Contact },
        { path: "/projects", view: Projects },
        { path: "/projects/:id", view: Project },
    ];

    // Test each route for potential match
    const potentialMatches = routes.map(route => {
        return {
            route: route,
            result: location.pathname.match(pathToRegex(route.path))
        };
    });

    let match = potentialMatches.find(potentialMatch => potentialMatch.result !== null);

    if (!match) {
        match = {
            route: routes[0],
            result: [location.pathname]
        };
    }

    const view = new match.route.view(getParams(match));

    document.querySelector("main").innerHTML = await view.getHtml();
};

window.addEventListener("popstate", router);

document.addEventListener("DOMContentLoaded", () => {
    document.body.addEventListener("click", e => {
        if (e.target.matches("[data-link]")) {
            e.preventDefault();
            navigateTo(e.target.href);
        }
    });

    router();
    transitionIn();
    transitionOut();
});